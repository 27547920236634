@import "/src/styles/Variables/Variables.scss";

.activebtn{
   border: none;
   color: $white-color;
   background-color: #50cd89;
   width: 70px;
   border-radius: 34px;
   line-height: 25px;
    
  //  box-shadow: 0 1px 2px #babfc7;
}
.inactivebtn{
  border: none;
  color: #dee2e6;
  background-color: #737a92;
  width: 70px;
  border-radius: 34px;
  line-height: 25px;
   
  // box-shadow: 0 1px 2px #babfc7;
}



/* Basic Rules */
.switch input { 
  display:none;
}
.switch {
  display:inline-block;
  width:50px;
  height:20px;
  margin:2px;
  transform:translateY(50%);
  position:relative;
}
/* Style Wired */
.slider {
  position:absolute;
  top:0;
  bottom:0;
  left:0;
  right:0;
  border-radius:30px;
  box-shadow:0 0 0 1px #777, 0 0 1px #777;
  cursor:pointer;
  border:4px solid transparent;
  overflow:hidden;
   transition:.2s;
}
.slider:before {
  position:absolute;
  content:"";
  width:100%;
  height:100%;
  background:#777;
  border-radius:30px;
  transform:translateX(-30px);
  transition:.2s;
}

input:checked + .slider:before {
  transform:translateX(30px);
  background:limeGreen;
}
input:checked + .slider {
  box-shadow:0 0 0 2px limeGreen,0 0 2px limeGreen;
}

/* Style Flat */
.switch.flat .slider {
box-shadow:none;
}
.switch.flat .slider:before {
background:#FFF;
}
.switch.flat input:checked + .slider:before {
background:white;
}
.switch.flat input:checked + .slider {
background:limeGreen;
}