@import "../../styles/Variables/Variables.scss";

#addform{
    width: 100%;
    border-radius: 5px;
    margin-top: 30px;
    margin-bottom: 20px;
   
    #addheading{
        font-size: 18px;
         
        margin-left: 10px;
    }

    .profilepicdiv{
        padding: 0px;
        border-radius: 5px;
        text-align: center;
    }

    .profilepic{
        width: 100%;
        border-radius: 5px;
    }
    .namesection{
        display: inline-block;
    }

    .profilename{
        font-size: 16px;
        margin-left: 20px;
        margin-top: 30px;
    }
    .profileemail{
        font-size: 14px;
         
        margin-left: 20px;
    }
   


        #empdiv{
            padding: 0px;
            margin-bottom: 20px;
            border-radius: 5px;
            display: flex;
            align-items: center;
            background-color: transparent;

            .title{
                 
                font-size: 13px;
            }

            .empname{
                font-size: 16px;
                color: $primary-color;
                font-weight: $font-semiBold;
                margin-bottom: 0px;
                // padding-bottom:8px;
            }
        }

        
        .arrowback{
            float: right;
            background-color: #dde2eb;
            padding: 10px;
            color: #205440;
            cursor: pointer;
            border-radius: 3px;
    
              &:hover{
                background-color: #205440;
                color: #fff;
              }
          }
}

.profile_view_container {
    padding: 30px;
}

.profile_view_container span {
    font-size: 13px;
    margin-bottom: 7px;
}

.back_dlt{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media only screen and (max-width: 600px) {
    #dashboardcont2{
        width: $dashwidth;
        left: $dashleft;
        position: relative;
        padding: $dashboardpadding;
    }
    .action_btns {
        display: flex;
    }
    .action_btns button {
        width: 100px;
        min-width: auto;
    }
}

