@import "../../styles/Variables/Variables.scss";
.settingsection{
    margin-bottom: 50px;
    padding-bottom:30px;

    #settingsheading{
        font-size: 18px;
    }
    .settingslinks{
        text-decoration: none;
        letter-spacing: 0.15em;
        display: inline-block;
        position: relative; 
    }

    .settingsgeneral{
        text-decoration: none;
        letter-spacing: 0.15em;
        display: inline-block;
        position: relative; 
        color: $white-color !important;
        background-color: $primary-btncolor;
    }

    
    .settingslinks:hover{
        color: $white-color !important;
        background-color: $primary-btncolor;
    }
    
    .settingsgeneral:hover{
        color: $white-color;
        background-color: $primary-btncolor;
      }

      #layoutnav{
        border-radius: 5px;
      }

}
                
            #addform{
                width: 100%;
                border-radius: 5px;
                margin-top: 30px;
                padding-bottom:30px;
                #addheading{
                    font-size: 18px;
                    margin-left: 10px;
                }
                .profilepicdiv{
                    border-radius: 5px;
                }
                .profilepic{
                    width: 100%;
                    height: auto;
                    border-radius: 5px;
                }
                .penicon{
                    margin-right: 5px;
                }

                .namesection{
                    display: inline-block;
                    margin-left: 20px;
                }

                .profilename{
                    font-size: 16px;
                    margin-top: 30px;
                }
                .profileedit{
                    font-size: 14px;
                     
                    text-decoration: none;
                    color: $white-color;
                    background-color: $primary-btncolor;
                    padding: 10px;
                    border-radius: 4px;
                }
            
                .editbtntop{
                    float: right;
                }


                    #empdiv{
                        padding:0px 0px 0px 15px;
                        margin-bottom: 10px;
                        border-radius: 5px;

                        .title{
                            font-size: 13px;
                            font-weight: $font-semiBold;
                            color: $text-graycolor;
                        }
                        &.viewformlight {
                            .empname{
                                color: $primary-color;
                            }
                        }
                        .empname{
                            font-size: 16px;
                            font-weight: $font-semiBold;
                            color: $white-color;
                            padding-bottom:0px;
                            word-break: break-word;
                        }
                    }
            }

.viewformlight {
    background-color: transparent;
}

.bodythemedark #addform.formthemedark .profileedit {
    background-color: $darkTheme_updateBtn;
    color: $white-color;
}

.bodythemelight #addform.formthemelight .profileedit {
    background-color: $primary-color;
    color: $white-color !important;
}

// @media only screen and (max-width: 1324px) {
//     .editbtntop{
//         margin-left: 40px !important;
//     }
//     }

// @media only screen and (max-width: 1324px) {
//     .editbtntop{
//         margin-left: 20px !important;
//     }
// }

// @media only screen and (max-width: 1200px) {
//     .editbtntop{
//         margin-left: 50px !important;
//     }
// }

 @media only screen and (max-width: 992px) {
    .editbtntop{
       float: left !important;
    }
}

@media only screen and (max-width: 878px) {
    #layoutnav{
        display: flex !important;
      }
      .settingslinks{
        margin-right: 8px !important;
        font-size: 14px !important;
    }
    .settingsgeneral{
        margin-right: 8px !important;
        font-size: 14px !important;
    }
    #addform .profilepic{ 
        margin-bottom: 30px;
    }
}

@media only screen and (max-width: 510px) {
      .settingslinks{
        margin-right: 6px !important;
        font-size: 13px !important;
    }
    .settingsgeneral{
        margin-right: 6px !important;
        font-size: 13px !important;
    }
}

@media only screen and (max-width: 495px) {
    .settingslinks{
      margin-right: 5px !important;
      font-size: 12px !important;
  }
  .settingsgeneral{
      margin-right: 5px !important;
      font-size: 12px !important;
  }
}


@media only screen and (max-width: 331px) {
  .settingsgeneral,  .settingslinks{
    padding: 12px !important;
  }
  .namesection{
    margin-left: 5px !important;

}
}




@media only screen and (max-width: 600px) {
    #dashboardcont2{
        width: $dashwidth;
        left: $dashleft;
        position: relative;
        padding: $dashboardpadding;
    }
}

