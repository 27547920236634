@import url('https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap');

body {
  font-family: "Urbanist", sans-serif !important;
}

/* 
scss
 */
$font-regular: 400;
$font-medium: 500;
$font-semiBold: 600;
$font-bold: 700;
$font-extraBold: 800;
$font-black: 900;

$mainBg: #F5F6FA;
$primary-color: #205440;
$icon-bgcolor: #F6F6F6;
$text-graycolor: #727272;
$cardIcon1-bg: #F3F4F8;
$cardIcon2-bg: #FAF4FB;
$cardIcon3-bg: #FAF7F3;
$cardIcon4-bg: #F5F9FC;
$cardIcon2-color: #AC44BB;
$cardIcon3-color: #D69A5F;
$cardIcon4-color: #176CA5;
$green-color: #38A169;
$darkGreen-color: #207a4a;
$placeholder-color: #666666;
$profile-dark-text: #181818;
$heading : #0B0D0F;
$light-gray : #999999;
$menu_icon : #CBCBCB;
$red-color : #F94747;



$darkTheme_blue : #132337;
$darkTheme_darkBlue : #0f1824;
$darkTheme_selectBlue : #1c2e45;
$darkTheme_updateBtn : #0a2574;

/* 
Body Theme Color
 */
$lightthemecolor: #f3f6f9;
$darkthemecolor: #151521;

$white-color: #fff;
$black-color: #1d1d1d;
$fullBlack-color: #1d1d1d;
$lightthemetext: #60677e;

/* 
Dark Theme Colors
*/
$primarydark-color: #1e1e2d;

/* 
Button Theme
*/
$primary-btncolor: #205440;
$secondary-btncolor: rgba(37, 105, 109, 0.864);

$tableheadlightbg: #e9eaf0;


/* 
Max Dashboard scss
*/
$dashboardwidth: calc(100% - 320px);
$dashboardposition: absolute;
$dashboardleft: 290px;
$dashboardpadding: 30px;

/* 
Min Dashboard scss
*/
$dashwidth: calc(100% - 50px);
$dashleft: 50px;

/* 
Max Dashboard scss
*/

.dashboard {
  width: calc(100% - 320px);
  position: relative;
  left: 290px;
  padding: 30px 30px 80px 30px;
  border-radius: 8px;
  min-height: auto !important;
  background-color: #F5F6FA;
  overflow-y: auto;
}
.dashboardContain { 
  width: calc(100% - 320px);
  background-Color:$mainBg;
  border-radius: 8px;
}
.bodythemedark .dashboardContain,
.bodythemedark .dashboard, 
.bodythemedark .cont2 { 
  background-Color: $darkTheme_darkBlue;
}
.top_header .top_header_inner {
  background-color: transparent;
  height: auto;
  overflow-y: unset;
}

/* 
Min Dashboard scss
*/

.cont2 {
  width: $dashwidth;
  left: $dashleft;
  position: relative;
  padding: $dashboardpadding;
  border-radius: 8px;
  background: $mainBg;
  /* 
background-Color: $dashboardbackgroundColor;
  padding-bottom: 8%;
*/
}

.subLink svg {
  position: relative;
  top: -2px;
  left: -7px;
}



.footer {
  height: 70px;
}

body {
  background-color: $white-color;
}

/* 
THEME CHANGE
*/
.bodythemelight {
  background-color: $white-color;
  padding-top: 90px;
}
.bodythemedark {
  background-color: $darkTheme_blue;
  padding-top: 90px;
}

/* 
Header Navbar Theme
*/
.headerthemelight {
  background-color: $white-color;
}

.headerthemedark {
  background-color: $darkTheme_blue;
}
.top_header {
  min-height: 90px;
  display: flex;
  align-items: center;
  position: fixed;
  width: 100%;
  z-index: 1; 
}

/* 
LEFT MENU THEME CHANGE
*/
.menuthemelight {
  background-color: $white-color;
}
.menuthemedark {
  background-color: $darkTheme_blue;
}

/* 
LEFT MENU LINKS THEME CHANGE
*/
.menulinklight span {
  color: $placeholder-color !important;
}

.menulinklight a {
  color: $menu_icon !important;
}

.menulinklight:hover span, .menulinklight:hover a { 
  color: $white-color !important;
}

.menulinkdarkSelected {
  background-color: $darkTheme_selectBlue;
  color: $white-color;
}
.menuthemelight .menulinklightSelected {
  /*background-color: $lightthemecolor;*/
  color: $white-color !important;
  background-color: $primary-color;
}

.menulinkdark {
  color: $lightthemetext !important;
}

.menulinklight:hover {
  color: $white-color !important;
  background-color: $primary-color;
}

.menulinkdark:hover {
  background-color: $darkTheme_selectBlue;
  color: $white-color;
}

/* 
BODY HEADERS
*/
.headinglight {
  color: $lightthemetext;
}

.headingdark {
  color: $lightthemetext;
}

/* 
SEARCH BOX THEME COLOR
*/
.searchboxlight {
  background-color: $white-color;
  border: none;
}

.searchboxdark {
  background-color: $darkTheme_blue;
  color: $white-color;
  border: none;
}
.searchboxdark::placeholder {
  color: $lightthemetext;
}

/* 
CUSTOM TABLE THEME COLOR
*/
.tableheadlight {
  background-color: $primary-color;
  border-radius: 5px 5px 0px 0px;
}
.tableheadlight th {
  color: $white-color;
}
thead .tableheadlight th:first-child,
thead .tableheaddark th:first-child {
  border-radius: 5px 0 0 0px;
} 
thead .tableheadlight th:last-child,
thead .tableheaddark th:last-child  {
  border-radius: 0 5px 0px 0;
}
th:first-child {
  border-radius: 0px;
} 
td:last-child {
  border-radius: 0px;
}
table.table.table-borderless{
  border-collapse: separate !important;
  border-spacing: 0 0px !important;
}

.tableheaddark {
  background-color: $primarydark-color;
  color: $white-color;
}
.table-borderless tr:nth-child(even) {
  background-color: #F9FAFB;
}
.table-borderless .left-section {
  padding-top: 10px;
}
.tablebodylight td, 
.tablebodydark td {
  padding: 1rem 1rem !important;
}
.tablebodylight {
  background-color: $white-color;

  &:hover {
    background-color: #ddecf9;
    cursor: pointer;
  }
}
.tablebodydark {
 /* background-color: $primarydark-color; */
  color: $lightthemetext;

  &:hover {
    background-color: rgba(33, 150, 243, 0.1);
    cursor: pointer;
  }
}

/* 
PAGINATION THEME COLOR
*/
.paginationlight {
  color: $lightthemetext !important;
  font-size: 13px !important;
}
.paginationdark {
  color: $lightthemetext !important;
  font-size: 13px !important;
}

/* 
SETTING FORM THEME COLOR
*/
.formthemelight {
  background-color: $white-color;
  color: $black-color !important;
  box-shadow: 0px 10px 35px 0px rgb(56 71 109 / 8%);
}
.formthemedark {
  background-color: $primarydark-color;
  color: $lightthemetext !important;
  font-size: 14px;

  ::placeholder {
    color: $lightthemetext;
  }
}


/* 
VIEW FORM DETAILS BOX THEME COLOR
*/
.viewformlight {
  background-color: $lightthemecolor;
}

.permissionboxlight {
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
}
.permissionboxdark {
  background-color: #151521;
  border: solid 1px #242434;
}

.errormsgclr {
  color: red;
  font-size: 14px;
  margin-top: 5px;
}

/* 
Graph theme
*/
.graphclrlight {
  background-color: $white-color;
}

.graphclrdark {
  background-color: $primarydark-color;
}


/* 
Counter theme
*/
#card1bglight {
  /* background: linear-gradient(90deg, rgba(181, 219, 177, 1) 13%, rgba(125, 178, 144, 1) 100%); */
  background-color: $white-color;
}
#card1bglight:hover {
  background: #f7fffc;
}
#card1bgdark {
  background: #392f28;
}
#card1bgdark:hover {
  background: #f7fffc;
}
#card2bglight {
  /* background: linear-gradient(90deg, rgba(243, 198, 165, 1) 0%, rgba(248, 163, 168, 1) 100%); */
  background-color: $white-color;
}
#card2bglight:hover {
  /* background: linear-gradient(90deg, rgba(243, 198, 165, 1) 0%, rgba(248, 163, 168, 1) 100%); */
  background-color: #f7fffc;
}
#card2bgdark {
  background: #1c3238;
}

#card3bglight {
  /* background: linear-gradient(90deg, rgba(213, 149, 211, 1) 0%, rgba(174, 132, 192, 1) 100%); */
  background-color: $white-color;
}
#card3bglight:hover {
  /* background: linear-gradient(90deg, rgba(213, 149, 211, 1) 0%, rgba(174, 132, 192, 1) 100%); */
  background-color: #f7fffc;
}

#card3bgdark {
  background: #3a2434;
}

#card4bglight {
  /* background: linear-gradient(90deg, rgba(140, 212, 214, 1) 0%, rgba(150, 202, 247, 1) 100%); */
  background-color: $white-color;
}
#card4bglight:hover {
  /* background: linear-gradient(90deg, rgba(213, 149, 211, 1) 0%, rgba(174, 132, 192, 1) 100%); */
  background-color: #f7fffc;
}
#card4bgdark {
  background: #2f264f;
}

#card1bgdark a, #card1bgdark p,
#card2bgdark a , #card2bgdark p,
#card3bgdark a, #card3bgdark p,
#card4bgdark a, #card4bgdark p {
  color: $white-color;
}

/* 
Action button theme
*/
.actionbtnlight {
  color: #46475bde;
}

.actionbtndark {
  color: $lightthemetext;
}


/* 
profile dropdowntheme
*/
.profiledropdownlight {
  background-color: #fff;
}

.profiledropdowndark {
  background-color: #151521;
}

.settingsection #addform.formthemedark .savebtn {
  background-color: $darkTheme_updateBtn;
  color: $white-color;
}
#addform.formthemedark .savebtn {
  background-color: $darkTheme_updateBtn;
  color: $white-color;
}

.profile_conatiner {
  border-radius: 5px !important; 
}

.bodythemedark .ag-theme-alpine .ag-row {
  background: #000 !important;
}

.bodythemedark .formbtn {
  background-color: $darkTheme_updateBtn;
}

.bodythemedark .resetbtn {
  color: $white-color;
  background-color: transparent;
  border: solid 1px $darkTheme_updateBtn;
}

.bodythemedark .resetbtn:hover {
  background-color: $darkTheme_updateBtn;
}


.headingdark span {
  background-color: $darkTheme_blue;
}

.resetbtn {
  font-size: 16px;
  font-weight: $font-semiBold;
  border-radius: 5px;
  background-color: transparent;
  border: solid 1px $primary-color;
  color:  $primary-color;
  min-width: 113px;
}
.resetbtn:hover, 
.resetbtn:active, 
.resetbtn:focus {
  background-color: $primary-color;
  color: $white-color;
}

.addBtn {
  min-width: 140px;
  height: 44px;
  color: $white-color;
  background-color: $green-color;
  border: none;
  font-size: 16px;
  font-weight: $font-semiBold;
}

.addBtn svg {
  margin-right: 10px;
  position: relative;
  top: 2px;
}

.addBtn:hover {
  color: $white-color;
  background-color: $green-color;
  border: none;
}

.cust_addBtn.addBtn svg {
  top: 2px;
}

.deleteBtn {
  min-width: auto;
padding: 10px 0;
  background-color: $red-color;
  color: $white-color;
  font-size: 16px;
  font-weight: $font-semiBold;
  border: none;
}

.deleteBtn {
  min-width: auto !important;
  height: 50px !important;
  background-color: $primary-color !important;
  color: $white-color !important;
  font-size: 16px !important;
  font-weight: $font-semiBold !important;
  border: none !important;
  padding: .375rem 1rem !important;
}

.deleteBtn:active, .deleteBtn:focus {
  background-color: $red-color !important;
  color: $white-color !important; 
  box-shadow: none !important;
}

.deleteBtn svg {
  position: relative;
  top: -2px;
}

.deleteBtn:hover, 
.deleteBtn:active, 
.deleteBtn:focus {
  background-color: $primary-color !important;
  color: $white-color;
  border: none;
}

.confirmBtn {
  background-color: $primary-color;
}

.confirmBtn:hover, 
.confirmBtn:active, 
.confirmBtn:focus {
  background-color: $primary-color;
}

.confirmBtn svg {
  top: 0;
}


.back_dlt{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.delPopContainer .modal-body {
  padding: 25px;
  text-align: center;
}

.delPopContainer .delet_icon {
  margin-bottom: 11px;
}

.delPopContainer p {
  color: #131f43;
  font-size: 14px;
  font-weight: 700;
}

.arrowback svg{
  fill: #000; 
}

.bodythemedark .arrowback {
  background-color: $darkTheme_updateBtn; 
  color: $white-color;
}

.bodythemedark .arrowback svg {
  fill: $white-color;
}

.bodythemedark .drpdwn_btn {
  background-color: $darkTheme_blue;
}

.bodythemedark .empname {
  color: $lightthemetext !important;
}

.bodythemedark .navitemli a {
  background-color: transparent;
  border: solid 1px $darkTheme_updateBtn;
  color: $white-color;
}

.bodythemedark .tabnavli a.settingsgeneral, 
.bodythemedark .tabnavli a.settingslinks:hover {
  background-color: $darkTheme_updateBtn;
  color: $white-color;
}
.bodythemedark .tabnavli a.settingslinks {
  background-color: transparent;
  border: solid 1px $darkTheme_updateBtn;
  color: $white-color;
}


@media only screen and (max-width: 1200px) {
  .tableheadlight th {
    min-width: 100px;
  }
}
@media only screen and (max-width: 1024px) { 
  .dashboard {
    width: calc(100% - 50px) !important;
    left: 50px !important;
    padding: 10px 10px !important;
  }
}
@media only screen and (max-width: 998px) { 
  #dashboardcont2{
    height: auto !important;
  }
}
@media only screen and (max-width: 767px) { 
  .sectionright{
    float: unset !important;
  }
  .dashboard {
    padding: 10px 10px !important;
  }
}
@media only screen and (max-width: 600px) {
  .cont2 {
    width: $dashwidth !important;
    left: $dashleft !important;
    position: relative !important;
    padding: 20px 10px !important;
  }
  .bodythemelight, .bodythemedark {
    padding-top: 165px;
  }
}

@media (min-width: 576px) {
  .exer_modal_contain .modal-dialog {
      max-width: 1055px;
  }
  .delPopContainer .modal-dialog {
      max-width: 350px;
  }
}