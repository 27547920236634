@import "../../styles/Variables/Variables.scss";

#addform{
    margin-top: 30px;
    padding: 30px;
    border-radius: 10px;

        #addheading{
            font-size: 16px;
            margin-bottom: 20px;
        }

        .selectdate{
            padding: 5px;
            border: 1px solid #ced4da;
            border-radius: 5px;
        }

        
        .savebtn{
            border: none;
            border-radius: 5px;
            background-color: $primary-btncolor;
            padding: 10px;
            color: $white-color;
            font-size: 14px;
            margin-top: 20px;
             

            &:hover{
                background-color: $secondary-btncolor;
            }
        }
}

@media only screen and (max-width: 767px) {
    .selectdate{
        width: 100%;
    }
}