@import "../../styles/Variables/Variables.scss";

.resetbtn {
    font-size: 16px;
    font-weight: 600;
    border-radius: 5px;
    background-color: transparent !important;
    border: solid 1px $primary-color !important;
    color: $primary-color !important;
    min-width: 113px;
    min-height: 50px;
    margin-right: 24px;
}

.resetbtn svg {
    margin-right: 10px;
    position: relative;
    top: -1px;
}


.deleteBtn svg {
    margin-right: 10px;
}


@media only screen and (max-width: 360px) {
    .resetbtn {
        min-width: 100px;
        margin-right: 15px;
    }
    .deleteBtn {
        min-width: 100px;
    }
}