@import "/src/styles/Variables/Variables.scss";

#tableheading{
  font-size: 18px;
   
  margin-top: 20px;
}

.sectionright{
  float: right;
}

.tableaddbtn{
    padding: 10px;
    min-width: 140px;
    border: none;
    background-color: $green-color;
    border-radius: 5px;
    color: $white-color; 
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: $font-semiBold;
    margin-left: 10px;

    &:hover{
      background-color: #207a4a;
    }
}

#actionbtn{ 
  border: none;
  margin-right: 10px;
  border-radius: 5px;
  background: none;
  font-weight: 100;
}


.headercolorlight {
  background-color: $primary-btncolor;
  color: $white-color;
  text-overflow: initial !important;
  
}
.headercolordark {
  background-color: #1e1e2d; 
  color: $white-color;
  text-overflow: initial !important;
}

#searchboxpad{
  padding-right: 0px;
}

.add_icon{
    margin-right: 5px;
    font-size: 20px;
    position: relative;
    top: 2px;
}

@media only screen and (max-width: 992px) {
  .sectionright{
    float: left;
  }
}